<script lang="ts" setup>
import type { icons } from '../assets/icons';
import { GBaseIcon } from '@gem/uikit';
import { computed } from 'vue';
import { cn } from '../helpers/common';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    label?: string;
    labelCls?: string;
    icon: keyof typeof icons;
    iconCls?: string;
    type?: 'danger' | 'default';
    tooltipContent?: string;
  }>(),
  {
    type: 'default',
    iconCls: 'pr-8',
  },
);

const emits = defineEmits<{
  (e: 'onClick'): void;
}>();

const clsIfDisabled = computed(() => {
  return props?.disabled && 'disabled:text-text-light-100 disabled:bg-white disabled:cursor-default';
});
const clsByTButton = computed(() => {
  switch (props?.type) {
    case 'danger':
      return 'text-red-300 hover:text-red-300';
    default:
      return 'text-dark-400 hover:text-dark-400';
  }
});

const onClick = () => {
  emits('onClick');
};
</script>

<template>
  <div>
    <GTooltip
      class="w-full"
      :disabled="!tooltipContent"
      placement="left"
      isTeleport="true"
      :content-styles="{ maxWidth: '280px' }"
      content-class="whitespace-pre-wrap">
      <template #content>
        {{ tooltipContent }}
      </template>
      <button
        class="rounded-medium hover:bg-light-450 text-14 font-regular flex h-[40px] w-full items-center justify-between p-8 leading-6"
        :class="cn(clsByTButton, clsIfDisabled)"
        :disabled="disabled"
        @click.stop.prevent="onClick">
        <div class="flex items-center">
          <div v-if="icon" :class="iconCls">
            <g-base-icon :name="icon" width="20px" height="20px" viewBox="0 0 20 20"></g-base-icon>
          </div>
          <div :class="labelCls">{{ label }}</div>
        </div>
        <slot name="suffix"></slot>
      </button>
    </GTooltip>
  </div>
</template>
